.home-screen {
    margin-top: 69px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px;
}

.todo-list-container {
    padding: 20px 0;
    width: 100%;
}

.task-container {
    max-width: 700px;
    width: 100%;
}