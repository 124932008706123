.list-selection-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.list-selection-dialog {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 400px;
    max-height: 80vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.list-selection-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #eee;
}

.list-selection-header h3 {
    margin: 0;
    font-size: 18px;
}

.close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #666;
}

.list-selection-content {
    padding: 16px;
    overflow-y: auto;
    max-height: 60vh;
}

.list-selection-items {
    list-style: none;
    padding: 0;
    margin: 0;
}

.list-selection-item {
    padding: 12px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.list-selection-item:hover {
    background-color: #f5f5f5;
}