.subtask-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    cursor: default;
    column-gap: 10px;
    border: 1px solid #eee;
    background-color: #fff;
    transition: background-color 0.2s ease;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.subtask-container:hover {
    background-color: #f8f9fa;
}

.subtask-buttons-container {
    display: flex;
    column-gap: 10px;
}


.subtask-text {
    flex: 1;
    cursor: pointer;
    padding: 5px 0;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.nested-subtasks {
    margin-left: 15px;
    border-left: 1px solid #eee;
    background-color: #fafafa;
}

.formatted-input {
    flex: 1;
    resize: none;
    min-height: 24px;
    max-height: calc(1.5em * 10);
    height: auto;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: inherit;
    font-size: inherit;
    line-height: 1.5;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    overflow-y: auto;
}

.formatted-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}
