.todo-add-step-container {
    display: flex;
    column-gap: 10px;
}

.add-button {
    font-size: 40px;
}

.formatted-input {
    flex: 1;
    resize: none;
    min-height: 24px;
    max-height: calc(1.5em * 10);
    height: auto;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: inherit;
    font-size: inherit;
    line-height: 1.5;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    overflow-y: auto;
}

.formatted-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}